import { connect } from 'react-redux';

import { getExperienceFragmentLocation } from 'redux/modules/experience-fragments/selectors';

const mapStateToProps = (state, props) => ({
  locationData: getExperienceFragmentLocation(
    state,
    props.experienceFragmentKey,
    props.locationName,
  ),
});

const mapDispatchToProps = {};

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
